


























































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    title03(): string {
      if (this.isCN && !this.isMobile) {
        return String(this.$t("AMHatsu.title03")).replace("<br />", " ");
      } else {
        return String(this.$t("AMHatsu.title03"));
      }
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_hatsu);
    },
    onVerify() {
      window.open(this.$paths.verify + "am-hatsu/", "_blank");
    }
  }
});
